@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
  @apply w-10/12 mx-auto;
}

body {
  background-color: #e9f2fa;
  min-height: 100vh;
  font-family: "Nunito", sans-serif;
}

.custom-input:focus {
  border: 0px !important;
  outline: 0px !important;
  box-shadow: 0px !important;
}

@media (max-width: 480px) {
  .main-container {
    @apply w-full mx-auto;
  }
}

/* For Webkit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #fff; /* Space around the thumb */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb hover color */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #fff; /* Thumb and track color */
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-wrap: wrap;
}

.loaderIcon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1.5s infinite linear;
}

.loaderIcon2 {
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/* Media query for higher zoom level */
@media (resolution > 120dpi) {
  .search-suggestions-grid {
    @apply gap-2;
  }
  .search-suggestions {
    @apply px-0;
  }
  .search-suggestions p {
    @apply text-xs;
  }
}

/* Media query for higher zoom level */
@media (resolution > 120dpi) {
  .search-suggestions-grid {
    @apply gap-1;
  }
  .search-suggestions {
    @apply px-0;
  }
  .search-suggestions p {
    font-size: 10px;
  }
}
